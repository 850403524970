export const ROOTS = {
  HOME: '/',
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  page500: '/500',
  page401: '/not-authorized',
  page503: '/service-unavailable',
  logoutPage: '/logout',
  home: ROOTS.HOME,
};
