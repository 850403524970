import { Helmet } from 'react-helmet-async';
// sections
import View401 from './components/View401';

export default function Page401() {
  return (
    <>
      <Helmet>
        <title>Not Authorized</title>
      </Helmet>
      <View401 />
    </>
  );
}
