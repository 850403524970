import { useFormContext, Controller } from 'react-hook-form';
// @mui
import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { CircularProgress } from '@mui/material';

// ----------------------------------------------------------------------

interface Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  name: string;
  label?: string;
  placeholder?: string;
  currentValue: any;
  helperText?: React.ReactNode;
}

export default function CasRHFAutoCompleteBase<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  name,
  label,
  placeholder,
  helperText,
  loading,
  currentValue,
  ...other
}: Omit<Props<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'>) {
  const { control, setValue } = useFormContext();

  const handleScroll = (event: any) => {
    const listboxNode = event.currentTarget;
    if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
      // when scrolled to bottom, fetch more data
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        field.value = currentValue;
        return (
        <Autocomplete
          {...field}
          onChange={(event, newValue, reason, details) =>{

            field.onChange(event, newValue);

            setValue(name, newValue, { shouldValidate: true });
            /*

            This type of handler seems to not report change if change
            was via form data change.

            Could maybe simplify dependent dropdown - as wanting to clear
            when a new selection made on parent.

            if (paramOnChange) {
              console.log(name, 'paramonchange');
              paramOnChange(event, newValue, reason, details);
            } else {
              console.log(name, 'no handler');
            } */
          }}
          ListboxProps={{
            onScroll: handleScroll,
            style: { height: '200px', overflow: 'auto' }, // adjust as needed
          }}
          renderInput={(params) => (
            <TextField
              label={label}
              placeholder={placeholder}
              error={!!error}
              helperText={error ? error?.message : helperText}
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading && <CircularProgress size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              InputLabelProps={{
                children: '',
                // Other InputLabelProps if needed
              }}
            />
          )}
          {...other}
        />
      )}}
    />
  );
}
