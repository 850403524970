// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// @tanstack/react-query
import { QueryClientProvider } from '@tanstack/react-query';

// theme
import ThemeProvider from 'src/theme';

// components
import MotionLazy from 'src/components/animate/motion-lazy';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
import { SettingsProvider } from 'src/components/settings';
import { queryClient } from 'src/config/reactQuery.config';
import { defaultSettings } from 'src/config/theme.config';

import { IContextChildren } from 'src/types/context.types';
import AuthContextProvider from './AuthContextProvider';
import ApiContextProvider from './ApiContextProvider/ApiContextProvider';
import CasMsalProvider from './CasMsalProvider';
import DialogContextProvider from './DialogContextProvider';

export default function CasContextProviders({ children }: IContextChildren) {
  return (
    <CasMsalProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SettingsProvider defaultSettings={defaultSettings}>
          <ThemeProvider>
            <MotionLazy>
              <SnackbarProvider>
                <QueryClientProvider client={queryClient}>
                  <ApiContextProvider>
                    <DialogContextProvider>
                      <AuthContextProvider>{children}</AuthContextProvider>
                    </DialogContextProvider>
                  </ApiContextProvider>
                </QueryClientProvider>
              </SnackbarProvider>
            </MotionLazy>
          </ThemeProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </CasMsalProvider>
  );
}
