import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import CasRHFSwitchBase, { CasRHFSwitchBaseProps } from './CasRHFSwitchBase';

type CasRHFSwitchProps = Omit<CasRHFSwitchBaseProps, 'onChange'> & {
  onChange?: (event: { target: { value: boolean } }, currentValue: boolean) => void;
};

export default function CasRHFSwitch({
  onChange,
  labelPlacement = 'end',
  ...props
}: CasRHFSwitchProps) {
  const { control } = useFormContext();

  const currentValue = useWatch({
    control,
    name: props.name, // the name of the input you want to watch
  });

  useEffect(() => {
    if (onChange) onChange({ target: { value: currentValue } }, currentValue);
  }, [currentValue, onChange]);

  return <CasRHFSwitchBase {...props} labelPlacement={labelPlacement} />;
}
