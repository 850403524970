// ----------------------------------------------------------------------

import prependAllOption from 'src/shared/util/prependAllOption';

export const UNASSIGNED_OPTIONS = prependAllOption([
  { label: 'Opened this month', value: 'OpenedThisMonth' },
  { label: 'Opened this month & Unassigned', value: 'OpenedThisMonthAndUnassigned' },
  { label: 'Unassigned', value: 'Unassigned' },
]);

export const PAGE_SIZE = 10;

export const PERMISSION_OPTIONS = [
  { type: 'all', isVisible: true },
  { type: 'add', isVisible: false },
  { type: 'view', isVisible: false },
  { type: 'edit', isVisible: false },
  { type: 'delete', isVisible: false },
];

export const MAXIMUM_LIMIT_MESSAGE = 'Your input exceeds the maximum length of 50 characters.';
export const VALID_NUMBER = 'Please enter a valid number.';

export const YESNO_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const IS_ACTIVE = prependAllOption(YESNO_OPTIONS);
