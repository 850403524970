import { useQueryClient } from '@tanstack/react-query';
import { Entity } from 'src/types/common.types';
import { useRetrieveDetailsById } from './reactQuery/useRetrieveDetailsById';

interface IPeriodDates {
  currentPeriodStart: string;
  currentPeriodEnd: string;
}

const PERIOD_DATES_QUERY_KEY = 'PeriodStartDateKey';

export const usePeriodDatesInvalidateQuery = () => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries({ queryKey: [PERIOD_DATES_QUERY_KEY] });
  };
};

export function usePeriodDates() {
  const { data, isError, ...rest } = useRetrieveDetailsById({
    queryKey: [PERIOD_DATES_QUERY_KEY],
    entity: Entity.PROPERTY,
    id: 1,
    staleTime: 3600000,
  });

  if (isError) {
    throw Error('Error retrieving current period start date');
  }

  const shapedPeriodDates: IPeriodDates = {
    currentPeriodStart: data?.currentPeriodStart || '',
    currentPeriodEnd: data?.currentPeriodEnd || '',
  };

  return { data: shapedPeriodDates, ...rest };
}
