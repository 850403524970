
import { formatDate, DateFormat } from 'src/shared/util/casMoment';
import useFirstOfMonthDateValues, { useDateValuesProps } from './useFirstOfMonthDateValues';

interface IFirstMonthDate extends useDateValuesProps {
  format?: DateFormat;
}

export default function useFirstOfMonthDateOptions({
  format = DateFormat.MDY,
  ...rest
}: IFirstMonthDate) {
  const dateValues = useFirstOfMonthDateValues(rest);

  const datesOptions = dateValues.map((date) => ({
    label: formatDate(date, format),
    value: date,
  }));

  return datesOptions;
}
