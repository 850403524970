import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { loginRequest } from '../config/auth.config';

export function aquireAuthToken(instance: any, account: any) {
    return new Promise((resolve) => {
    instance
        .acquireTokenSilent({
            ...loginRequest,
            account,
        })
        .catch((error: any) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(loginRequest);
            }
        })
        .then((result: { accessToken: any; }) => resolve(result?.accessToken));
    })
}