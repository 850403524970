import { VALID_NUMBER } from 'src/constants';
import * as Yup from 'yup';

const MAXIMUM_MESSAGE = 'must not be more than 100%.';
const MINIMUM_MESSAGE = 'must be greater than 0%.';
const REQUIRED = 'is required.';

export default function getPercentSchema(
  customMessage: string,
  max: number = 100,
  maxDecimalPlaces: number = 3
) {
  return Yup.number()
    .typeError(VALID_NUMBER)
    .required(`${customMessage} ${REQUIRED}`)
    .moreThan(0, `${customMessage} ${MINIMUM_MESSAGE}`)
    .max(max, `${customMessage} ${MAXIMUM_MESSAGE}`)
    .test('maxDecimals', `Field is limited to ${maxDecimalPlaces} decimal places.`, (value) => {
      const decimalPlaces = (value.toString().split('.')[1] || '').length;
      return decimalPlaces <= maxDecimalPlaces;
    });
}
