import { Entity } from 'src/types/common.types';
import { useRetrieveEntityList } from './reactQuery/useRetrieveEntityList';

// get listing date for options when there is no payload

export function useGetEntityOptions(entity: Entity) {
  const { data, ...rest } = useRetrieveEntityList({
    entity,
    params: {
      isActive: true,
      pageNumber: 1,
      pageSize: 100,
      sortColumn: 'name',
      sortOrder: 'asc',
    },
    onSuccess: (response: any) =>
      response?.result?.map((option: any) => ({
        value: option.id,
        label: option.name,
      })),
  });

  return { data: data ?? [], ...rest };
}
