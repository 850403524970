import { VALID_NUMBER } from 'src/constants';
import * as Yup from 'yup';
import getMaximumDecimalSchema from './getMaximumDecimalSchema';

export default function getNumberSchema(
  customMessage: string,
  moreThanNumber: number = 0,
  maxDecimalPlaces: number = 2
) {
  return Yup.number()
    .concat(getMaximumDecimalSchema(maxDecimalPlaces))
    .typeError(VALID_NUMBER)
    .required(`${customMessage} is required.`)
    .moreThan(moreThanNumber, `${customMessage} must be greater than $${moreThanNumber}.`);
}
