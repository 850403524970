import { Typography } from '@mui/material';
import Iconify from 'src/components/iconify';

import CasMessageDialog from './CasMessageDialog';
import { ICasBaseDialog } from './CasBaseDialog';
import { CasStackRow } from '../CasStack/CasStackRow';

type CasErrorDialogProps = ICasBaseDialog & {
  error: Error;
};

export default function CasErrorDialog({ error, ...rest }: CasErrorDialogProps) {
  return (
    <CasMessageDialog
      content={
        <CasStackRow alignItems="center" justifyContent="center" gap="1rem">
          <Iconify
            width={28}
            height={28}
            color="red"
            icon="material-symbols:warning"
            flexShrink={0}
          />
          <Typography sx={{ wordBreak: 'break-word', whiteSpace:'pre-line' }}>{error.toString()}</Typography>
        </CasStackRow>
      }
      {...rest}
    />
  );
}
