import CasErrorDialog from './CasErrorDialog';

export interface CasMutationErrorDialogProps {
    error: Error;
    title?: string
    onClose: VoidFunction
}

export default function CasMutationErrorDialog({ error, title, onClose }: CasMutationErrorDialogProps) {  
        return <CasErrorDialog
                    open 
                    title={title ? `ERROR: ${title}` : "ERROR"}
                    error={error}
                    onClose={()=>onClose()} 
                />

}