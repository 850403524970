export const downloadFile = (exportedExcelFile: string, fileName: string) => {
  try {
    if (!exportedExcelFile) throw new Error('No data to export.');

    // Decode base64 data
    const decodedData = atob(exportedExcelFile);

    // Convert the decoded data to a Uint8Array
    const uint8Array = new Uint8Array(decodedData.length);

    for (let i = 0; i < decodedData.length; i += 1) {
      uint8Array[i] = decodedData.charCodeAt(i);
    }

    // Create a Blob from the Uint8Array
    const blob = new Blob([uint8Array], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    // Create a download link and simulate a click to download the file
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = fileName;
    downloadLink.click();
  } catch (error) {
    throw new Error('No data to export.');
  }
};
