/* eslint-disable react/jsx-no-useless-fragment */
import "./CasTableToolbar.scss";

import { ReactNode } from 'react';
import valueClassname from 'src/shared/util/valueClassname';
import CasPopoverMenu, { IcasPopoverOption } from '../CasPopoverMenu/CasPopoverMenu';
import CasTextField from '../CasTextField/CasTextField';
import CasSelect from '../CasSelect/CasSelect';
import { CasStackRow } from "../CasStack/CasStackRow";

export interface ICasTableToolbar {
  onFilterChange: (name: string, value: string) => void;
  filters?: any;
  currentFilterValues: any;
  buttons: ReactNode[];
  options?: IcasPopoverOption[];
}

const CasTableToolbar = ({
  onFilterChange,
  filters,
  currentFilterValues,
  buttons,
  options,
}: ICasTableToolbar) => {
  const renderFilters = () =>
    filters?.map((filter: any) => {
      switch (filter.type) {
        case 'select':
          return (
            <CasSelect
              className={valueClassname(currentFilterValues[filter.name])}
              label={filter.label}
              key={filter.label}
              value={currentFilterValues[filter.name]}
              name={filter.name}
              sx={{minWidth: Math.max(85, filter.label.length * 8)}}
              onChange={(_, value) => onFilterChange(filter.name, value)}
              placeholder={filter.label}
              options={filter.options}
              optionsLabelField={filter.optionsLabelField}
              optionsValueField={filter.optionsValueField}
            />
          );

        default:
          return (
            <CasTextField
              className={valueClassname(currentFilterValues[filter.name])}
              name={filter.name}
              key={filter.name}
              label={filter.label}
              onChange={(_, value) => onFilterChange(filter.name, value)}
              enabledDebounce={filter.enabledDebounce}
            />
          );
      }
    });

  const empty = (filters===undefined || filters?.length===0)
    && (buttons===undefined || buttons?.length===0);

  return (
    <CasStackRow
      className={`CasTableToolbar control-no-margin ${  empty ? "empty" : ""}`}
      gap={1}
      alignItems="center"
    >
      <CasStackRow alignItems="center" gap={2} flexGrow={1}>
        {renderFilters()}
      </CasStackRow>
      <CasStackRow alignItems="center" gap={2} justifyContent="flex-end">
        {buttons}
      </CasStackRow>
      {options && options?.length > 0 && <CasPopoverMenu options={options} /> }
    </CasStackRow>
  );
};

export default CasTableToolbar;
