import { VALID_NUMBER } from 'src/constants';
import * as Yup from 'yup';
import getMaximumDecimalSchema from './getMaximumDecimalSchema';

export default function getNonZeroNumberSchema(
  customMessage: string,
  maxDecimalPlaces: number = 2
) {
  return Yup.number()
    .concat(getMaximumDecimalSchema(maxDecimalPlaces))
    .typeError(VALID_NUMBER)
    .required(`${customMessage} is required.`)
    .notOneOf([0], `${customMessage} cannot be $0.`);
}
