import { useRetrieveEntityList } from 'src/shared/hooks/reactQuery/useRetrieveEntityList';
import { Entity } from 'src/types/common.types';
import prependNoneOption from 'src/shared/util/prependNoneOption';
import CasRHFSelect from '../CasSelect/CasRHFSelect';

export function useRiskWithSalesManager() {
  const { data, isLoading } = useRetrieveEntityList({entity: Entity.RISK_MANAGER_WITH_SALES});
  return [data?.result || [], isLoading];
}

export default function CasRiskWithSalesManagerSelect({ includeNoneOption, ...rest }: any) {
  const [options, isLoading] = useRiskWithSalesManager();

  const selectOptions = includeNoneOption ? prependNoneOption(options) : options

  return <CasRHFSelect {...rest} loading={isLoading} options={selectOptions} />;
}
