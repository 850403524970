import { useContext } from 'react';
// @mui
import Box from '@mui/material/Box';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { AuthContext } from 'src/context/AuthContextProvider';
import { useSettingsContext } from 'src/components/settings';
// components
import { Main, Header, NavMini, NavVertical } from 'src/layouts/dashboard';

import ImpersonateUserAppBar from './ImpersonateUserAppBar';

type Props = {
  children: React.ReactNode;
};

export default function SiteLayout({ children }: Props) {
  const settings = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini />;

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;

  const { impersonatingUserName } = useContext(AuthContext);

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'row' },
        }}
      >
        {lgUp && isMini ? renderNavMini : renderNavVertical}

        <Main>{children}</Main>

        {impersonatingUserName && (
          <ImpersonateUserAppBar impersonatingUserName={impersonatingUserName} />
        )}
      </Box>
    </>
  );
}
