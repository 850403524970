import CasBaseDialog from './CasBaseDialog';
import CasButton from '../CasButton/CasButton';

export default function CasConfirmationDialog({
  open,
  title = '',
  content,
  confirmLabel = 'OK',
  onConfirm,
  onCancel,
}: any) {
  return (
    <CasBaseDialog
      {...{ open, title, content, onClose: onCancel }}
      actions={
        <>
          <CasButton color="secondary" variant="outlined" onClick={onCancel}>
            Cancel
          </CasButton>
          <CasButton onClick={onConfirm}>{confirmLabel}</CasButton>
        </>
      }
    />
  );
}
