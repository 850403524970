import { Box, Container } from "@mui/material";

export default function HomeView() {
  return <Container maxWidth={false}>
    <Box>
      <h4>Welcome to the Compass Ag Solutions Portal!</h4>
    </Box>
</Container>
  
}
