import { useGetEntityOptions } from 'src/shared/hooks/useGetEntityOptions';
import { Entity } from 'src/types/common.types';
import CasRHFSelect from '../CasSelect/CasRHFSelect';

export default function CasBranchSelect(props: any) {
  const { data, isLoading } = useGetEntityOptions(Entity.BRANCH);

  return (
    <CasRHFSelect
      {...props}
      loading={isLoading}
      options={data}
      optionsIdField="value"
      optionsNameField="label"
    />
  );
}
