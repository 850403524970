import { Method, AxiosError } from 'axios';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { useApiContext } from 'src/context/ApiContextProvider/ApiContextProvider';
import { onErrorDefaultHandler } from 'src/shared/util/onErrorHandlers';

interface IUseAPI {
  method: Method;
  url: string;
  data?: Object;
  staleTime?: number;
  gcTime?: number;
  enabled?: boolean;
  onSuccess?: Function;
  onError?: (error: AxiosError) => any;
  queryKey?: string[];
  params?: Record<string, any>
}

export function useAPI({
  method,
  url,
  data = {},
  staleTime = 0,
  gcTime,
  enabled = true,
  onSuccess,
  onError = onErrorDefaultHandler,
  queryKey,
  params,
}: IUseAPI): UseQueryResult<any, AxiosError> {
  const { api } = useApiContext();

  return useQuery({
    queryKey: queryKey || [url, { data, params }],
    queryFn: async () => {
      try {
        const response = await api({ method, url, data, params });
        if (onSuccess) return onSuccess(response.data);
        return response.data;
      } catch (error) {
        return Promise.reject(onError(error));
      }
    },
    staleTime,
    gcTime,
    enabled,
  });
}
