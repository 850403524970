import { createContext, useContext, useMemo } from 'react';
import { useMsal } from '@azure/msal-react';
import { IContextChildren, IGeneralObjectType } from 'src/types/context.types';
import { axiosAuthInstance } from './axiosAuthInstance';
import { AuthContext } from '../AuthContextProvider';

export const ApiContext = createContext<IGeneralObjectType>({});

export function useApiContext() {
  return useContext(ApiContext);
}

const ApiContextProvider = ({ children }: IContextChildren) => {
  const { instance } = useMsal();
  const { account } = useContext(AuthContext);
  const api = axiosAuthInstance(instance, account);

  const valueObj = useMemo(() => ({ api }), [api]);

  return <ApiContext.Provider value={valueObj}>{children}</ApiContext.Provider>;
};

export default ApiContextProvider;
