import { useRetrieveEntityList } from 'src/shared/hooks/reactQuery/useRetrieveEntityList';
import { Entity } from 'src/types/common.types';
import CasRHFSelect from '../CasSelect/CasRHFSelect';

export function useAdditionalCompensation() {
  const { data, isLoading } = useRetrieveEntityList({entity: Entity.INS_COMM_TYPE_SELECT});
  return [data?.result || [], isLoading];
}

export default function CasAdditCompensationSelect(props: any) {
  const [options, isLoading] = useAdditionalCompensation();

  return <CasRHFSelect {...props} loading={isLoading} options={options} />;
}
