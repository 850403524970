import { Box } from "@mui/material";
import { formatDate,  DateFormat } from 'src/shared/util/casMoment';
import { AsOfColor } from "src/types/common.types";

export default function CasRenderAsOfDate({row}:any) {
    return (
        row?.displayAsOfDate && (
            <Box sx={{ fontSize: 'inherit', color: AsOfColor.RateColor, fontWeight:'bold', textAlign: 'right' }}>
              {formatDate(row?.asOfDate, DateFormat.MD)}
            </Box>
          )
    );
}