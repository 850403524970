/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useCallback, useState, useMemo } from 'react';

// @mui
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';

// components
import Scrollbar from 'src/components/scrollbar';
import { TableHeadCustom, TablePaginationCustom, useTable } from 'src/components/table';
import formatCurrency from 'src/shared/util/formatCurrency';
import { useExportOption } from 'src/shared/util/useExportOption';
import defaultLabelFromField from 'src/shared/util/defaultLabelFromField';
import { IGeneralObjectType } from 'src/types/context.types';
import  useRetrieveEntity from 'src/shared/hooks/reactQuery/useRetrieveEntity';
import CasTableToolbar, { ICasTableToolbar } from './CasTableToolbar';
import CasTableBody, { TRowSx } from './CasTableBody';
import ICasTableColumn from './ICasTableColumn';
import CasDisplayField from '../CasDisplayField/CasDisplayField';

export interface ICasTable
  extends Omit<ICasTableToolbar, 'onFilterChange' | 'buttons' | 'currentFilterValues'> {
  entity?: string;
  data?: IGeneralObjectType;
  toolbarButtons?: React.ReactNode[];
  columns: ICasTableColumn[];
  includeExport?: boolean;
  update?: Date | null;
  showPagination?: boolean;
  defaultOrder?: 'asc' | 'desc';
  rowSx?: TRowSx;
  //  Share response data with parent
  setData?: React.Dispatch<React.SetStateAction<any>>;
  additionalPayload?: IGeneralObjectType;
  headerDisplayFields?: { label: string; name: string; type?: string }[];
}

interface Option {
  value: string;
  label: string;
}

//  Function to find the first object without sortable:true and return its column name
function findNonSortableColumn(columnArray: any[]) {
  const nonSortableObj = columnArray.find((obj) => obj.sortable !== false);
  if (nonSortableObj) return nonSortableObj.field;
  return null;
}

function tableRequestFormattedData(
  tableData: IGeneralObjectType,
  additionalpayload: IGeneralObjectType
) {
  const { filters: fil, ...rest }: any = tableData || {};
  const modFilters: any = { ...fil };
  Object.keys(modFilters)?.forEach((key: string) => {
    if (modFilters[key] === 'All') delete modFilters[key];
  });
  const formattedData = { ...rest, ...modFilters, ...additionalpayload };
  return formattedData;
}

export default function CasTable({
  entity,
  data: responseFromParent,
  columns,
  filters,
  toolbarButtons = [],
  includeExport = true,
  options,
  update = null,
  showPagination = true,
  defaultOrder = 'asc',
  additionalPayload = {},
  rowSx,
  setData,
  headerDisplayFields,
}: ICasTable) {
  if (entity && responseFromParent) throw Error("Can't specify both entity and data.");
  if (!entity && !responseFromParent) throw Error('Either entity or data must be specified.');

  // dynamically find the column to sort
  const defaultOrderBy = useMemo(() => findNonSortableColumn(columns), [columns]);
  const table = useTable({ defaultOrderBy, defaultOrder });

  const [casTableData, setCasTableData] = useState<any>(null);

  const formattedData = useMemo(
    () => tableRequestFormattedData(casTableData, additionalPayload),
    [casTableData, additionalPayload]
  );

  const {
    data: responseFromTable,
    error,
    isFetching,
    refetch,
  } = useRetrieveEntity({
    entity: entity ?? null,
    params: formattedData,
    enabled: !!casTableData && !responseFromParent,
  });

  const data = responseFromParent ?? responseFromTable;

  useEffect(() => {
    if (update) refetch();
  }, [update, refetch]);

  useEffect(() => {
    // Notify parent of updated raw data
    if (setData && data) setData(data);
  }, [data]);

  useEffect(() => {
    const tableData: any = {};
    const filtersData: { [key: string]: string | boolean | Option | null } = {};
    filters?.forEach((filter: any) => {
      filtersData[filter.name] = filter.initialValue;
    });
    tableData.filters = filtersData || {};
    if (showPagination && entity) {
      tableData.pageNumber = table.page + 1;
      tableData.pageSize = table.rowsPerPage;
    }
    if (defaultOrderBy) {
      tableData.sortColumn = table.orderBy;
      tableData.sortOrder = table.order;
    }
    setCasTableData(tableData);
  }, []);

  useEffect(() => {
    if (showPagination && casTableData && entity) {
      const pageNum = table.page + 1;
      if (casTableData.pageNumber !== pageNum || casTableData.pageSize !== table.rowsPerPage) {
        setCasTableData((prev: any) => ({
          ...prev,
          pageNumber: table.page + 1,
          pageSize: table.rowsPerPage,
          sortColumn: table.orderBy,
          sortOrder: table.order,
        }));
      }
    }
  }, [table.page, table.rowsPerPage]);

  useEffect(() => {
    if (defaultOrderBy && casTableData) {
      if (casTableData.sortColumn !== table.orderBy || casTableData.sortOrder !== table.order) {
        if (table.page !== 0) {
          const newPage = 0;
          table.onChangePage(null, newPage);
        } else
          setCasTableData((prev: any) => ({
            ...prev,
            sortColumn: table.orderBy,
            sortOrder: table.order,
          }));
      }
    }
  }, [table.orderBy, table.order]);

  const handleFilters = useCallback(
    (name: string, value: string) => {
      table.onResetPage();
      // check if column contains onChange, to uplift the value.
      const filterObject = filters.find((filter: any) => filter.name === name);
      filterObject?.onChange?.(value);

      setCasTableData((prevState: any) => ({
        ...prevState,
        filters: { ...prevState.filters, [name]: value },
      }));
    },
    [table]
  );

  const { exportOption } = useExportOption({
    entity,
    params: formattedData,
    includeExport: entity ? includeExport : false,
  });

  const finalOptions = options ? [...exportOption, ...options] : exportOption;

  const displayFieldComponents = headerDisplayFields?.map(({ label, name, type }, index) =>
    data && name in data ? (
      <CasDisplayField
        key={index}
        label={label}
        value={type === 'Currency' ? formatCurrency(data[name]) : data[name]}
      />
    ) : null
  );
  if (displayFieldComponents) {
    toolbarButtons = [...displayFieldComponents, ...toolbarButtons];
  }

  const headLabel = useMemo(
    () =>
      columns.map((column) => ({
        ...column,
        id: column.id || column.field,
        label: column.label ? column.label : defaultLabelFromField(column.field),
        sortable: entity ? column.sortable : false,
      })),
    [columns]
  );

  return (
    <>
      <CasTableToolbar
        buttons={toolbarButtons}
        filters={filters}
        currentFilterValues={casTableData?.filters || {}}
        onFilterChange={handleFilters}
        options={finalOptions}
      />

      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <Scrollbar>
          <Table size="medium" sx={{ minWidth: 960 }}>
            <TableHeadCustom
              headLabel={headLabel}
              rowCount={data?.result?.length}
              onSort={table.onSort}
              order={table.order}
              orderBy={table.orderBy}
            />

            <CasTableBody
              loading={isFetching}
              columns={columns}
              rowData={data?.result}
              error={error?.toString() ?? ''}
              rowSx={rowSx}
            />
          </Table>
        </Scrollbar>
      </TableContainer>
      {showPagination && entity && (
        <TablePaginationCustom
          count={data?.totalCount || 0}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
        />
      )}
    </>
  );
}
