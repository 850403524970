import { CasBaseDialog, CasButton, CasDeleteButton } from 'src/shared/components';
import { ICasBaseDialog } from './CasBaseDialog';

export type CasDialogProps = ICasBaseDialog & {
  id?: string;
  canDelete: boolean;
  deleteLabelSuffix: string;
  saveButtonLabel: string;
  onConfirmDelete: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onSave: VoidFunction;
};

export default function CasDialog({
  onClose,
  id,
  canDelete = true,
  deleteLabelSuffix,
  saveButtonLabel,
  onConfirmDelete,
  onSave,
  ...other
}: CasDialogProps) {
  return (
    <CasBaseDialog
      {...other}
      onClose={onClose}
      actions={
        <>
          {canDelete && id && (
            <CasDeleteButton deleteLabelSuffix={deleteLabelSuffix} onClick={onConfirmDelete} />
          )}
          <CasButton color="secondary" variant="outlined" onClick={onClose}>
            Cancel
          </CasButton>
          <CasButton onClick={onSave}>{saveButtonLabel}</CasButton>
        </>
      }
    />
  );
}
