import { FormControl, InputLabel } from '@mui/material';

interface ICasControl {
  label: string;
  children: JSX.Element;
}

export default function CasControl({ label, children }: ICasControl) {
  if (label === null) return children;
  return (
    <FormControl
      sx={{
        flexShrink: 0,
      }}
    >
      <InputLabel>{label}</InputLabel>
      {children}
    </FormControl>
  );
}
