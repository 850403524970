import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { CasBaseDialog, CasButton, CasDatePicker } from 'src/shared/components';
import { usePeriodDates } from 'src/shared/hooks/usePeriodDates';
import { getDateInMoment, isSameDayInDateList } from 'src/shared/util/casMoment';
import { ICasBaseDialog } from 'src/shared/components/CasDialog/CasBaseDialog';
import { Custom_Label } from './CasAsOf';

export type CasDialogProps = Omit<ICasBaseDialog, 'title'> & {
  onDateSelected: Function;
  dateList: string[];
  initialAsOfList: any[];
};

export default function CasDatePopUp({
  onClose,
  onDateSelected,
  dateList,
  initialAsOfList,
  ...other
}: CasDialogProps) {
    const { data } = usePeriodDates();
    const { currentPeriodStart } = data;

  const { setValue } = useFormContext();
  const [currentDateSelected, setCurrentDateSelected] = useState<Date | null>(null);

  const handleDateChange = (date: Date) => setCurrentDateSelected(date);

  const handleDateSubmit = () => {
    onDateSelected(currentDateSelected);
    setValue('date', null);
    onClose();
  };

  const handleDisableDates = (date: Date) => {
    // Array of specific dates you want to disable

    const disabledDates = dateList.filter(dateValue => dateValue !== Custom_Label).map((filteredDate) => getDateInMoment(filteredDate));
    /* for mode => new as of, we have to disable both dateList
     i.e next 6 months and for api response => as of dates.
     */
    const otherDates = initialAsOfList.map((asOf: any) => getDateInMoment(asOf.asOfDate));
    const isDisabled = isSameDayInDateList(date, disabledDates) || isSameDayInDateList(date, otherDates)

    return isDisabled;
  };

  const modalOnClose = () => {
    onDateSelected(dateList[0]);
    setValue('date', null);
    onClose();
  };

  return (
    <CasBaseDialog
      {...other}
      fullWidth={false}
      title="Select Date"
      content={
        <CasDatePicker
          name="date"
          handleDateChange={handleDateChange}
          minDate={currentPeriodStart}
          shouldDisableDate={handleDisableDates}
        />
      }
      onClose={modalOnClose}
      actions={
        <>
          <CasButton variant="outlined" color="secondary" onClick={modalOnClose}>
            Cancel
          </CasButton>
          <CasButton variant="contained" onClick={handleDateSubmit} disabled={!currentDateSelected}>
            OK
          </CasButton>
        </>
      }
    />
  );
}
