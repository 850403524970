import infoUriFromEntity from 'src/shared/util/infoUriFromEntity';
import useRetrieve from './useRetrieve';

interface IUseRetrieveEntity {
  entity: string | null;
  params?: Record<string, any>;
  staleTime?: number;
  enabled?: boolean;
  onSuccess?: Function;
}

export default function useRetrieveEntity({
  entity,
  params,
  staleTime = 0,
  enabled = true,
  onSuccess,
}: IUseRetrieveEntity) {
  if (entity === null && enabled) throw new Error("Can't be enabled when entity is null.");

  return useRetrieve({
    url: entity ? infoUriFromEntity(entity) : '',
    params,
    staleTime,
    enabled,
    onSuccess,
  });
}
