import * as Yup from 'yup';
import { MAXIMUM_LIMIT_MESSAGE } from 'src/constants';

export default function getEmailSchema(customMessage: string = 'Email', maxLimit: number = 50) {
  return (
    Yup.string()
      .trim()
      .email('Invalid email')
      .required(`${customMessage} is required`)
      .max(maxLimit, MAXIMUM_LIMIT_MESSAGE)
      // Browser doesn't flag entry without period as error.  e.g. joe@gmail
      .matches(/^\S+@\S+\.\S+$/, { message: 'Invalid email', excludeEmptyString: true })
  );
}
