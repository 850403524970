import { useMemo } from 'react';

// components
import {
  CasDatePicker,
  IEditForm,
  CasEditForm,
  CasRHFTextField,
} from 'src/shared/components';
import { Entity } from 'src/types/common.types';

import { usePeriodDatesInvalidateQuery } from 'src/shared/hooks/usePeriodDates';
import { formatDate, DateFormat } from 'src/shared/util/casMoment';
// schema
import { PropertySchema } from './PropertySchema';

export default function PropertyForm({ row, onSaved }: IEditForm) {
  const defaultValues = useMemo(() => row, [row]);
  const invalidatePeriodStartDate = usePeriodDatesInvalidateQuery();

  const onBeforeSave = (data: any) => ({
    ...data,
    closedThruDate: formatDate(data.closedThruDate, DateFormat.YMD),
  });

  const onSaveSuccess = (data:any) => {
    invalidatePeriodStartDate();
    onSaved(data);
  };

  return (
    <CasEditForm
      defaultValues={defaultValues}
      onSaved={onSaveSuccess}
      entity={Entity.PROPERTY}
      schema={PropertySchema}
      onBeforeSave={onBeforeSave}
    >
      <CasDatePicker
        name="closedThruDate"
        label="Closed through date"
        helperText="Commissions processing has been closed through this date"
      />

      <CasRHFTextField label="LRP Default Payroll Export Code" name="lrpPayrollExportCode" />
    </CasEditForm>
  );
}
