// @mui
import { Theme, SxProps } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
//
import EmptyContent from 'src/components/empty-content/empty-content';

// ----------------------------------------------------------------------

type Props = {
  sx?: SxProps<Theme>;
};

export default function CasTableError({ sx }: Props) {
  return (
    <TableRow>
      <TableCell colSpan={12}>
        <EmptyContent
          filled
          title="Error loading data"
          sx={{
            py: 10,
            ...sx,
          }}
        />
      </TableCell>
    </TableRow>
  );
}
