import * as Yup from 'yup';

export default function getMaximumDecimalSchema(maxDecimalPlaces: number = 2) {
  return Yup.number().test(
    'maxDecimals',
    `Field is limited to ${maxDecimalPlaces} decimal places.`,
    (value) => {
      const decimalPlaces = (value?.toString().split('.')[1] || '').length;
      return decimalPlaces <= maxDecimalPlaces;
    }
  );
}
