import { AxiosRequestConfig, Method } from 'axios';
import {
  DefaultError,
  UseMutationOptions,
  UseMutationResult,
  useMutation,
} from '@tanstack/react-query';

import { useDialog } from 'src/context/DialogContextProvider';
import { useApiContext } from 'src/context/ApiContextProvider/ApiContextProvider';
import { onSuccessEnqueueSnackbar } from 'src/shared/util/onSuccessEnqueueSnackbar';
import { onErrorDefaultHandler } from 'src/shared/util/onErrorHandlers';
import { CasMutationErrorDialog } from 'src/shared/components';

export interface GenericMutationProps
  extends UseMutationOptions<unknown, DefaultError, any, unknown> {
  errorTitle?: string;
}

export type GenericMutationResult = {
  mutation: UseMutationResult<unknown, DefaultError, Object, unknown>;
};

interface useCasMutationProps extends GenericMutationProps {
  method: Method;
  url: string;
  config?: AxiosRequestConfig;
  params?: Record<string, any>
}

export default function useCasMutation({
  method,
  url,
  config,
  params,
  onSuccess = onSuccessEnqueueSnackbar,
  onError,
  errorTitle,
  ...rest
}: useCasMutationProps) {
  const { api } = useApiContext();
  const dialog = useDialog();

  const mutation = useMutation({
    mutationFn: async (data: Object) => {
      try {
        const response = await api({ method, url, data, params, ...config });
        return response.data;
      } catch (error) {
        return Promise.reject(onErrorDefaultHandler(error))
      }
    },
    onSuccess,
    onError,
    ...rest,
  });

  if (mutation.isError) {
    dialog.open(CasMutationErrorDialog, { error: mutation.error, title: errorTitle });
  }

  return mutation;
}
