/* eslint-disable */
import { useRoutes } from 'react-router-dom';
import AuthenticationHOC from 'src/hocs/WithAuthentication';
import { Page401, Page404, Page500, Page503 } from 'src/pages/error';
import { LogoutPage } from 'src/pages/authentication';
import useDashboardRoutes  from '../hooks/useDashboardRoutes';
import { paths } from '../paths';
import { useIsAuthenticated } from '@azure/msal-react';
import { HomePage } from 'src/pages/home';


export default function Router() {
  const protectedDashboardRoutes = useDashboardRoutes();
  const isAuthenticated = useIsAuthenticated();

  // not logged  in case
  if (!isAuthenticated) {
    return useRoutes([
      {
        path: '*',
        element: AuthenticationHOC({
          Component: <HomePage />,
        }),
      },
    ]);
  }

  const routing: any = [
    ...protectedDashboardRoutes,
    { 
      path: paths.logoutPage,
      element: <LogoutPage />
    },
    {
      path: paths.page401,
      element: <Page401 />,
    },
    {
      path: paths.page503,
      element: <Page503 />,
    },
    { path: paths.page500, element: <Page500 /> },
    { path: '*', element: <Page404 /> },
  ];

  return useRoutes(routing);
}
