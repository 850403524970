import CasBaseDialog from './CasBaseDialog';
import CasButton from '../CasButton/CasButton';

export default function CasMessageDialog({ open, title = '', content, onClose }: any) {
  return (
    <CasBaseDialog
      {...{ open, title, content, onClose }}
      actions={
        <div style={{ textAlign: 'center', flexGrow: 1 }}>
          <CasButton onClick={onClose}>OK</CasButton>
        </div>
      }
    />
  );
}
