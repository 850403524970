import React from 'react';
import { Navigate } from 'react-router-dom';
// Msal imports
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { LoadingScreen } from 'src/components/loading-screen';
import { loginRequest } from '../config/auth.config';
import { paths } from '../routes/paths';

interface AuthenticatedComponentProps {
  Component: any;
}

const AuthenticationHOC: React.FC<AuthenticatedComponentProps> = ({ Component }) => {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={() => <Navigate to={paths.page401} />}
      loadingComponent={() => <LoadingScreen />}
    >
      {Component}
    </MsalAuthenticationTemplate>
  );
};

export default AuthenticationHOC;
