
/*
will compare dates 
is fist date after the another ?
*/

import { getDateInMoment } from "./casMoment";

export default function isDateAfter(date1: string, date2: string) {
  const newDate1 = getDateInMoment(date1);
  const newDate2 = getDateInMoment(date2);
  return newDate1 > newDate2;
}
