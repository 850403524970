// components
import { CasTableAndDialog } from 'src/shared/components';
// types
import { Entity } from 'src/types/common.types';
import PropertyForm from './PropertyForm';

type PropertyTabProps = {
  showEditSingletonButton?: boolean;
};

export default function PropertyTab({ showEditSingletonButton = false }: PropertyTabProps) {
  return (
    <CasTableAndDialog
      entityTitle='Property'
      entity={Entity.PROPERTY}
      columns={[
        {
          field: 'property',
          sortable: false,
        },
        {
          field: 'value',
          sortable: false,
        },
      ]}
      showPagination={false}
      showAddButton={false}
      showEditSingletonButton={showEditSingletonButton}
      includeExport={false}
      editForm={PropertyForm}
      canDelete={false}
    />
  );
}
