import useCasMutation, { GenericMutationProps } from 'src/shared/hooks/reactQuery/useCasMutation';

type uploadMutationProps = GenericMutationProps & { url: string };

export default function useUploadMutation({ url, ...rest }: uploadMutationProps) {
  return useCasMutation({
    method: 'post',
    url,
    config: {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
    ...rest,
  });
}
