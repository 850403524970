import { useRef, useEffect } from 'react';

import { ReturnType, useBoolean } from 'src/hooks/use-boolean';
import { IGeneralObjectType } from 'src/types/context.types';
import { useRetrieveDetailsById } from 'src/shared/hooks/reactQuery/useRetrieveDetailsById';
import CasDialog from '../CasDialog/CasDialog';
import CasDeletePopUp from '../CasDeletePopUp/CasDeletePopUp';
import CasErrorDialog from '../CasDialog/CasErrorDialog';
import CasLoaderWithBackdrop from '../CasLoader/CasLoaderWithBackdrop';

interface ICasEditableDialog {
  id?: any;
  entityTitle:string,
  editForm: any;
  entity: string;
  dialog: ReturnType;
  onChanged: Function;
  canDelete?: boolean;
  onChildDelete?: VoidFunction;
  deleteLabelSuffix?: string;
  editFormAdditionalData?: IGeneralObjectType;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export default function CasEditableDialog({
  id,
  entityTitle,
  editForm: EditForm,
  entity,
  dialog: editDialog,
  onChanged,
  canDelete = false,
  onChildDelete,
  deleteLabelSuffix ="",
  maxWidth = "sm",
  editFormAdditionalData
}: ICasEditableDialog) {
  const editMode = !!id;

  const { data, isFetching, isError, error } = useRetrieveDetailsById({
    entity,
    id,
    enabled: !!id,
  });

  const containerRef = useRef<HTMLSpanElement>(null);

  const deleteDialog = useBoolean();
  const errorDialog = useBoolean();

  useEffect(() => {
    if (isError) errorDialog.onTrue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  if (!editDialog.value) return null;

  if (isFetching) return <CasLoaderWithBackdrop />;

  return (
    <>
      <CasDialog
        id={id}
        content={
          <span ref={containerRef}>
            <EditForm
              row={data}
              onSaved={(savedData:any) => {
                onChanged(savedData);
                editDialog.onFalse();
              }}
              onChildDelete={onChildDelete}
              editFormAdditionalData={editFormAdditionalData}
            />
          </span>
        }
        open={editDialog.value && !error}
        saveButtonLabel={editMode ? 'Update' : 'Save'}
        title={(editMode ? 'Edit ' : 'Add ') + entityTitle}
        onSave={() => {
          const node = containerRef.current as any;
          const form = node.querySelector('form');

          form.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
        }}
        onClose={() => editDialog.onFalse()}
        onConfirmDelete={(e: any) => {
          e.preventDefault();
          deleteDialog.onTrue();
        }}
        canDelete={canDelete}
        maxWidth={maxWidth}
        deleteLabelSuffix={deleteLabelSuffix}
      />

      {deleteDialog.value && canDelete && (
        <CasDeletePopUp
          entity={entity}
          entityTitle={entityTitle}
          id={id}
          onDeleted={() => {
            deleteDialog.onFalse();
            onChanged();
          }}
          open={deleteDialog.value}
          onClose={() => {
            deleteDialog.onFalse();
            editDialog.onTrue();
          }}
        />
      )}

      {error && (
        <CasErrorDialog
          open={errorDialog.value}
          onClose={() => {
            errorDialog.onFalse();
            editDialog.onFalse();
          }}
          error={error}
          title={`${entityTitle} Form`}
        />
      )}
    </>
  );
}
