import { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';

import { Container, Typography, Box } from '@mui/material';

import CasErrorBoundary from '../CasErrorBoundary/CasErrorBoundary';

export interface ICasPage {
  pageTitle: string;
  title: string;
  titleSuffix?: string | JSX.Element;
  children: ReactNode;
  className?: string;
}

export default function CasPage({
  pageTitle,
  title,
  titleSuffix,
  children,
  className = '',
}: ICasPage) {
  return (
    <>
      <Helmet>
        <title>{`${pageTitle} : ${title}`}</title>
      </Helmet>
      <CasErrorBoundary>
        <Container maxWidth={false} className={className}>
          <Box>
            <Typography className='page-title' variant="h4" width={1} gutterBottom>
              {title}
              {titleSuffix}
            </Typography>
          </Box>
          {children}
        </Container>
      </CasErrorBoundary>
    </>
  );
}
