import axios, { AxiosInstance } from 'axios';
import { IPublicClientApplication } from '@azure/msal-browser';

import { aquireAuthToken } from 'src/utils/auth-token';

const baseURL = process.env.REACT_APP_API_URL as string;

export const axiosAuthInstance = (
  msalInstance: IPublicClientApplication,
  account: any
): AxiosInstance => {
  const instance = axios.create({
    baseURL,
    headers: {
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': '*',
    },
  });

  instance.interceptors.request.use(async (config) => {
    const accessToken: any = await aquireAuthToken(msalInstance, account);
    config.headers.Authorization = `Bearer ${accessToken ?? ''}`;
    return config;
  });

  return instance;
};
