import { Stack, StackProps } from "@mui/material";

type CasStackRowProps  = StackProps & {
    children: React.ReactNode
}

export function CasStackRow({children, ...rest}:CasStackRowProps) {
    return <Stack flexDirection="row" gap="2rem" {...rest}>
        {children}
    </Stack>
}