import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';

// components
import { TableNoData, TableCircularProgress } from 'src/components/table';
import CasTableCell from './CasTableCell';
import ICasTableColumn from './ICasTableColumn';
import CasTableError from './CasTableError';

export type TRowSx = (row: any) => SxProps<Theme>;

interface ICasTableBody {
  loading: boolean;
  columns: ICasTableColumn[];
  rowData: any[];
  error: null | string;
  rowSx?: TRowSx;
}

export default function CasTableBody({
  loading = false,
  columns,
  rowData,
  error,
  rowSx,
}: ICasTableBody) {
  return (
    <TableBody>
      {loading ? (
        <TableCircularProgress loading={loading} />
      ) : (
        rowData?.map((row, index) => (
          <TableRow key={index} sx={{ ...(rowSx ? rowSx(row) : {}) }} hover>
            {columns?.map((column, colIndex) => (
              <CasTableCell key={`${index}${colIndex}`} row={row} column={column} />
            ))}
          </TableRow>
        ))
      )}
      <TableNoData notFound={!rowData?.length && !loading && !error} />
      {error && <CasTableError />}
    </TableBody>
  );
}
