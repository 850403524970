import { useEffect } from 'react';
import { AutocompleteProps } from '@mui/material/Autocomplete';
import { useFormContext } from 'react-hook-form';
import CasRHFAutoCompleteBase from './CasRHFAutoCompleteBase';

interface Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'onChange'> {
  name: string;
  label?: string;
  placeholder?: string;
  helperText?: React.ReactNode;
  onChange?: (event: { target: { value: any } }, value: any) => void;
  optionsIdField?: string;
  optionsNameField?: string | Function;
}

export default function CasRHFAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  onChange,
  optionsIdField = 'id',
  optionsNameField = 'name',
  ...props
}: Omit<Props<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'>) {
  const { watch } = useFormContext();

  const currentValue = watch(props.name);

  const getDesc = (option: any) => typeof optionsNameField === 'function'
    ? optionsNameField(option)
    : (option as any)[optionsNameField];

    useEffect(() => {
      if (onChange) onChange({ target: { value: currentValue } }, currentValue);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentValue]);

  return (
    <CasRHFAutoCompleteBase
      currentValue={currentValue}
      {...props}
      getOptionLabel={(option) => getDesc(option) ?? ""}
      isOptionEqualToValue={(option, value) =>
        value &&
        (option as any)[optionsIdField] === (value as any)[optionsIdField]
      }
      renderOption={(rProps, option) => (
        <li {...rProps} key={(option as any)[optionsIdField]}>
          {getDesc(option)}
        </li>
      )}
    />
  );
}
