import { getErrorNavigate } from 'src/App';
import { paths } from 'src/routes/paths';

function onErrorBaseHandler(error: any) {
  const { response, code } = error;
  const errorNavigate = getErrorNavigate()

  if (code === 'ERR_NETWORK' || code === 'ERR_NETWORK_ACCESS_DENIED') {
    return errorNavigate(paths.page503); // Stop further execution
  }

  if (response) {
    const { status, data } = response;
    if (status === 401 || status === 403) {
      if (data === 'Token expired') {
        return errorNavigate(paths.logoutPage); // Stop further execution
      }

      return errorNavigate(paths.page401); // Stop further execution
    }
  }
  return error;
}

export function onErrorPermissionsHandler(error: any) {
  const errorNavigate = getErrorNavigate()
  const errorFromDefault = onErrorBaseHandler(error);
  if (errorFromDefault) errorNavigate(paths.page500);
}

export function onErrorDefaultHandler(error: any) {
  const errorFromDefault = onErrorBaseHandler(error);
  if (!errorFromDefault) {
    return undefined; // Error has been handled by onErrorBaseHandler
  }

  const { response } = errorFromDefault;

  if (response) {
    const { status, data } = response;

    if (status === 400 || status === 409 || status === 500) {
      const errorMessage = (Object.values(data?.errors)?.flat()?.join('\n ')) || 'Something went wrong';
      return errorMessage;
    }
  }

  return error;
}
