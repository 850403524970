/* eslint-disable  react-hooks/exhaustive-deps */

import { useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import isEqual from 'lodash/isEqual';
import { useRetrieveEntityList } from 'src/shared/hooks/reactQuery/useRetrieveEntityList';
import CasRHFSelect, { CasEntitySelectProps } from './CasRHFSelect';

type IDependentSelect = CasEntitySelectProps & {
  primaryDependentValue: string;
  additionalDependentValue: string[];
  param: string;
  entity: string;
  name: string;
};

function useDependentSelect(entity: string, params: Record<string, any>, enabled: boolean = true) {
  const { data, isLoading } = useRetrieveEntityList({ entity, params, enabled });
  return [data?.result || [], isLoading];
}

export default function DependentSelect({
  primaryDependentValue,
  additionalDependentValue,
  param,
  name,
  entity,
  ...otherProps
}: IDependentSelect) {
  const { setValue, control } = useFormContext();

  const primaryDependentCurrentValue = useWatch({ control, name: primaryDependentValue });
  const additionalDependentCurrentValue = useWatch({ control, name: additionalDependentValue });

  const primaryDependentValueRef = useRef(primaryDependentCurrentValue);
  const additionalDependentValueRef = useRef(additionalDependentCurrentValue);

  const [depsUpdated, setDepsUpdated] = useState(false);

  const [options, isLoading] = useDependentSelect(
    entity,
    { [param]: primaryDependentCurrentValue },
    !!primaryDependentCurrentValue
  );

  useEffect(() => {
    if (
      (primaryDependentValueRef.current &&
        primaryDependentValueRef.current !== primaryDependentCurrentValue) ||
      !isEqual(additionalDependentValueRef.current, additionalDependentCurrentValue)
    ) {
      setDepsUpdated(true);
    }
  }, [primaryDependentCurrentValue, additionalDependentCurrentValue]);

  // This will now only run once either id or date are updated
  useEffect(() => {
    if (depsUpdated) {
      if (
        primaryDependentValueRef.current !== primaryDependentCurrentValue &&
        isEqual(additionalDependentValueRef.current, additionalDependentCurrentValue)
      ) {
        setValue(name, null);
      }
      primaryDependentValueRef.current = primaryDependentCurrentValue;
      additionalDependentValueRef.current = additionalDependentCurrentValue;
      // Reset depsUpdated
      setDepsUpdated(false);
    }
  }, [depsUpdated]);

  return <CasRHFSelect {...otherProps} name={name} loading={isLoading} options={options} />;
}
