import { useFormContext, useWatch } from 'react-hook-form';
// utils
import formatCurrency from 'src/shared/util/formatCurrency';
// comp
import CasDisplayField from '../CasDisplayField/CasDisplayField';
import CasMultiRowTable, { ICasMultiRowTable } from './CasMultiRowTable';

interface ICasMultiRowWithTotal extends ICasMultiRowTable {
  keyForTotal: string;
}

export default function CasMultiRowWithTotal({
  name,
  keyForTotal,
  ...props
}: ICasMultiRowWithTotal) {
  const { control } = useFormContext();
  const currentValue = useWatch({ control, name });

  const total =
    currentValue?.reduce((accumulator: number, currentItem: any) => {
      const value = currentItem[keyForTotal];
      if (typeof value === 'number') {
        return accumulator + value;
      }
      return accumulator;
    }, 0) || 0;

  return (
    <>
      <CasMultiRowTable name={name} {...props} />
      <CasDisplayField label="Total" value={formatCurrency(total)} />
    </>
  );
}
