import { downloadFile } from 'src/utils/download-file';

import useCasMutation, { GenericMutationProps } from './useCasMutation';

type DownloadExcepProps = GenericMutationProps & {
  entity: string;
  params?: Record<string, any>
};

export default function useExportExcelMutation({ entity, params, ...rest }: DownloadExcepProps) {
  return useCasMutation({
    method: 'get',
    url: `/${entity}/Export`,
    params,
    onSuccess: (data: any) => {
      downloadFile(data.exportedExcelFile, `${data.fileName}`);
    },
    ...rest,
  });
}
