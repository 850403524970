import { useRetrieveEntityList } from 'src/shared/hooks/reactQuery/useRetrieveEntityList';
import { Entity } from 'src/types/common.types';

export default function useRiskManager() {
  const { data, isLoading } = useRetrieveEntityList({entity: Entity.EMPLOYEE_RISK_MANAGER});

  return [
    data?.result?.map((option: any) => ({
      value: option.id,
      label: option.name,
    })) || [],
    isLoading,
  ];
}
