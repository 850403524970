import { useState, useMemo, useCallback, useEffect } from 'react';

import { useBoolean } from 'src/hooks/use-boolean';
// types
import { IGeneralObjectType } from 'src/types/context.types';
import ICasEditInfoProps from './ICasEditInfoProps';
// components
import CasTable, { ICasTable } from './CasTable';
import CasEditableDialog from './CasEditableDialog';
import ICasTableColumn from './ICasTableColumn';
import ICasRenderCellProps from './ICasRenderCellProps';
import CasLink from '../CasLink/CasLink';
import CasButton from '../CasButton/CasButton';
import Iconify from '../../../components/iconify/iconify';

type TCasShowEdit = ({ row, column }: ICasRenderCellProps) => boolean;

type TCasEditRowInfo = {
  row: any | null;
  info?: ICasEditInfoProps | null;
};

interface ICasTableAndDialogColumn extends ICasTableColumn {
  // Convenience property. When true, makes cell link that opens edit dialog
  showEditOnclick?: boolean | TCasShowEdit;
  clickedCellFormInfo?: (row: any) => ICasEditInfoProps;
}

interface ICasTableAndDialog extends Omit<ICasTable, 'update' | 'entity'> {
  entityTitle: string;
  entity: string;
  editForm: any;
  deleteLabelSuffix?: string;
  canDelete?: boolean;
  columns: ICasTableAndDialogColumn[];
  showAddButton?: boolean;
  showEditSingletonButton?: boolean;
  editFormAdditionalData?: IGeneralObjectType;
  reloadTable?: Date | null;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export default function CasTableAndDialog({
  entityTitle,
  editForm,
  entity,
  deleteLabelSuffix = '',
  canDelete = true,
  columns: parmColumns,
  showAddButton = true,
  showEditSingletonButton = false,
  toolbarButtons: parmToolbarButtons = [],
  editFormAdditionalData = {},
  reloadTable = null,
  maxWidth = 'sm',
  ...rest
}: ICasTableAndDialog) {
  const dialog = useBoolean();

  const [editRowInfo, setEditRowInfo] = useState<TCasEditRowInfo>({
    row: null,
    info: {
      id: null,
      editForm: null,
      entity: null,
      entityTitle: null,
      deleteLabelSuffix: null,
      canDelete: true,
    },
  });

  const [update, setUpdate] = useState<Date | null>(null);

  useEffect(() => {
    if (reloadTable) setUpdate(new Date());
  }, [reloadTable]);

  const onChanged = () => {
    setUpdate(new Date());
    onClose();
  };

  const onClose = () => {
    dialog.onFalse();
  };

  /*
     CasTableAndDialog includes an extra showEditOnclick column property.
     If set for a column, we need to adjust the columns sent to CasTable
  */
  const adjustedColumns = useMemo(
    () =>
      parmColumns.map((parmColumn) => {
        if (!parmColumn.showEditOnclick) return parmColumn;

        const origRender = parmColumn.render;

        // When showEditOnclick is enabled, need to specify custom render
        return {
          ...parmColumn,
          render: ({ row, column }: ICasRenderCellProps) => {
            const { showEditOnclick } = column as ICasTableAndDialogColumn;

            const showLink =
              showEditOnclick instanceof Function
                ? showEditOnclick({ row, column })
                : showEditOnclick;

            const Value = ({ row: R, column: C }: ICasRenderCellProps) =>
              origRender ? origRender({ row: R, column: C }) : R?.[C.field];

            return showLink ? (
              <CasLink
                onClick={() => {
                  setEditRowInfo({
                    row,
                    info: parmColumn.clickedCellFormInfo
                      ? parmColumn.clickedCellFormInfo(row)
                      : null,
                  });
                  dialog.onTrue();
                }}
              >
                <Value {...{ row, column }} />
              </CasLink>
            ) : (
              <Value {...{ row, column }} />
            );
          },
        };
      }),
    [parmColumns, dialog]
  );

  const [data, setData] = useState(null);

  const handleAddAction = useCallback(() => {
    setEditRowInfo({ row: null, info: null });
    dialog.onTrue();
  }, [dialog]);

  // Data is only currently needed for Edit button
  const handleEditAction = useCallback(() => {
    setEditRowInfo({ row: data, info: null });
    dialog.onTrue();
  }, [dialog, data]);

  const adjustedButtons = useMemo(
    () => [
      ...parmToolbarButtons,

      ...(showAddButton
        ? [
            <CasButton
              onClick={handleAddAction}
              startIcon={<Iconify icon="mingcute:add-line" />}
              key="add"
            >
              Add
            </CasButton>,
          ]
        : []),

      ...(showEditSingletonButton
        ? [
            <CasButton
              onClick={handleEditAction}
              startIcon={<Iconify icon="material-symbols:edit" />}
              key="edit"
            >
              Edit
            </CasButton>,
          ]
        : []),
    ],
    [parmToolbarButtons, handleAddAction, handleEditAction, showAddButton, showEditSingletonButton]
  );

  const onChildDelete = () => setUpdate(new Date());

  return (
    <>
      <CasTable
        entity={entity}
        columns={adjustedColumns}
        {...rest}
        toolbarButtons={adjustedButtons}
        setData={
          // Data is only currently needed for Edit button
          showEditSingletonButton ? setData : undefined
        }
        update={update}
      />
      {dialog.value && (
        <CasEditableDialog
          entityTitle={editRowInfo?.info?.entityTitle ?? entityTitle}
          maxWidth={maxWidth}
          entity={editRowInfo?.info?.entity ?? entity}
          deleteLabelSuffix={editRowInfo?.info?.deleteLabelSuffix ?? deleteLabelSuffix}
          editForm={editRowInfo?.info?.editForm ?? editForm}
          dialog={dialog}
          id={editRowInfo.info?.id ?? editRowInfo.row?.id}
          onChanged={onChanged}
          canDelete={editRowInfo?.info?.canDelete ?? canDelete}
          onChildDelete={onChildDelete}
          editFormAdditionalData={editFormAdditionalData}
        />
      )}
    </>
  );
}
